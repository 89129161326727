<script lang="ts" setup>
import useCpBem from '~/composables/useCpBem';
import CpSectionHeader from '~/components/cp/CpSection/CpSectionHeader/CpSectionHeader.vue';
import {useI18n} from 'vue-i18n';
import CpCardShoppingToolsHome from '~/components/cp/CpCardShoppingToolsHome/CpCardShoppingToolsHome.vue';
import CpButton from '~/components/cp/CpButton/CpButton.vue';
import type {ITool} from '~/utils/services/api';

defineProps<{
    cards: Array<ITool>
}>();

const {t} = useI18n();
const {b, e} = useCpBem('cp-section-shopping-tools-home');
</script>
<template>
    <div :class="b">
        <CpSectionHeader :title="t('needHelp')" type="h2" show-top-border />
        <div :class="e`content`">
            <div :class="e('slider')">
                <CpCardShoppingToolsHome v-for="(item, index) in cards"
                    :key="index"
                    :link="item.link"
                    :label="item.title"
                    :picture="item.picture"
                />
            </div>
            <div :class="e('button-container')">
                <CpNuxtLink
                    data-testid="go-to-shopping-tools"
                    link="https://www.cyberpuerta.mx/Herramientas-de-compra/"
                >
                    <CpButton
                        :class="e('button')"
                        :label="t('seeAllTools')"
                        kind="label"
                        color="primary"
                        hollow
                        full-width
                    />
                </CpNuxtLink>
            </div>
        </div>
    </div>
</template>
<style scoped lang="scss">
@import "assets/style/global.scss";
.cp-section-shopping-tools-home{
  width: 100%;
  overflow: hidden;
  border-bottom: 1px solid var(--color-neutral-extra-light);
  &__content {
    padding: var(--cp-gutter);
  }
  &__button-container {
    padding-top: $cp-gutter;
  }
  &__slider {
    display: flex;
    gap: $cp-gutter;
    overflow: auto;
  }
  &__slider::-webkit-scrollbar {
    height: 0;
    display: none;
  }
}
</style>
<i18n locale="es-US">
  needHelp: '¿Necesitas ayuda con tu compra?'
  seeAllTools: 'Ver todas las herramientas'
</i18n>
